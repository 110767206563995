.modal-width-140 {
  @media (min-width: 576p) {
    /* 스마트폰 가로 */
    width: 100% !important;
  }

  @media (min-width: 768px) {
    /* 타블렛 */
    width: 100% !important;
  }
  @media (min-width: 992px) {
    /* 데스크탑 */
    width: 130% !important;
  }
}

.modal-width-140 {
  @media (min-width: 576p) {
    /* 스마트폰 가로 */
    width: 100% !important;
  }

  @media (min-width: 768px) {
    /* 타블렛 */
    width: 100% !important;
  }
  @media (min-width: 992px) {
    /* 데스크탑 */
    width: 140% !important;
  }
}

.modal-width-150 {
  @media (min-width: 576p) {
    /* 스마트폰 가로 */
    width: 100% !important;
  }

  @media (min-width: 768px) {
    /* 타블렛 */
    width: 100% !important;
  }
  @media (min-width: 992px) {
    /* 데스크탑 */
    width: 150% !important;
  }
}

.modal-width-180 {
  width: 150% !important;
  @media (min-width: 576p) {
    /* 스마트폰 가로 */
    width: 150% !important;
  }

  @media (min-width: 768px) {
    /* 타블렛 */
    width: 180% !important;
  }
  @media (min-width: 992px) {
    /* 데스크탑 */
    width: 180% !important;
  }
}
