/** @format */

/*=====================================================*/
/*=== 아코디언 ===*/
.accordion .card {
  box-shadow: none !important;
  background-color: #f6f9fc !important;
}
.accordion .card .card-body {
  background-color: #f6f9fc !important;
}
/*=====================================================*/
.rounded-card {
  border-radius: 20px !important;
}
.card {
  border-radius: 3px !important;
  border-color: #ddd !important;
}
.model-content > .card:hover {
  border-color: #ddd !important;
}
/*=====================================================*/
.card-footer {
  background-color: #fff !important;
  border-top: 1px solid #eee !important;
}
/*=====================================================*/

.-card-h {
  height: calc(100% - 35px);
}
.rounded-xl {
  border-radius: 0.75em !important;
}
.rounded-2xl {
  border-radius: 1em !important;
}
.rounded-3xl {
  border-radius: 1.5em !important;
}
.rounded-4xl {
  border-radius: 2em !important;
}
.rounded-5xl {
  border-radius: 2.5em !important;
}

/*=====================================================*/
.share-card {
  background-color: white;
  border-radius: 7px;
  height: 100%;
  word-wrap: break-word;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.share-card:hover {
  box-shadow: 0px 0px 8px 0px rgba(4, 17, 29, 0.151);
  transform: translateY(-2px);
  transition: all 0.1s ease 0s;
}
.share-card:hover > .face-share-icon {
  color: #1876f3;
}

.share-card:hover > .kakao-share-icon img {
  background-color: #ffd500;
  border-radius: 3px;
}
.share-card:hover > .twitter-share-icon {
  color: #5395ff;
}
/*=====================================================*/
.no-border-card {
  background-color: #fff !important;
  border: 0 !important;
}
.no-border-visitor-card {
  background-color: #f6f9fc !important;
  border: 0 !important;
}
.white-border-card {
  background-color: #f2f4f7 !important;
  border: 3px solid #fff !important;
}
.gray-border-card {
  background-color: #f9fafc !important;
  border: 1px solid #ddd !important;
}
.no-bg-card {
  background-color: transparent !important;
  border: 0 !important;
}
.gray-bg-card {
  background-color: #f6f9fc !important;
  border: 0 !important;
}
.white-bg-card {
  background-color: #fff !important;
  border: 1px solid #eee !important;
  border-radius: 3px;
  width: 100%;
}
/*=====================================================*/
/* blue-card */
.blue-card {
  background-color: #5395ff !important;
}
.blue-inverse-card {
  background-color: rgba(36, 138, 253, 0.2) !important;
}
/* info-card */
.info-card {
  background-color: #68afff !important;
}
/* info-card */
.yellow-card {
  background-color: #f5a622 !important;
}
/* black-card */
.black-card {
  background-color: #333 !important;
}
/* black-card */
.gray-card {
  background-color: #555 !important;
}
/* opcity-card */
.card-opacity {
  background-color: rgba(255, 255, 255, 0.514) !important;
}
.card-dark-opacity {
  background-color: rgba(0, 0, 0, 0.048) !important;
}
