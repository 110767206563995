.title-box {
  border-radius: 25px;
  box-shadow: 4px 4px 7px rgba(94, 104, 121, 0.3);
  background-color: #fff;
}

.inset-content-box {
  box-shadow: inset 3px 3px 6px rgba(94, 104, 121, 0.4);
  background-color: #f1f6fa;
}
