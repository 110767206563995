.header-menu {
  color: #777;
  font-size: 15px;

  &:hover {
    color: #5395ff;
    transition: ease-in-out 0.25s;
    -webkit-transition: ease-in-out 0.25s;
  }

  &:active {
    color: #5395ff;
    transition: ease-in-out 0.25s;
    -webkit-transition: ease-in-out 0.25s;
  }

  @media (max-width: 1069px) {
    padding: 7px 5px;
  }
}

.header-menu-active {
  font-size: 15px;
  color: #5395ff;
  transition: ease-in-out 0.25s;
  -webkit-transition: ease-in-out 0.25s;
}
