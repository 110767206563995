/** @format */

/*=====================================================*/
/* 이미지 박스안에 비율 유지상태로 꽉차게 */
.img-wrap-box {
  overflow: hidden;
}
.img-wrap-box > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/*=====================================================*/
/* 이미지 중앙정렬 및 넘치면 자르기 */
.img-cover-box {
  overflow: hidden;
  object-fit: cover;
  background-size: cover;
}
.img-cover-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
}
.img-cover-box > a {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
}
.img-cover-box > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
}

/*=====================================================*/
/* 이미지 100% */
.img-full {
  width: 100%;
  height: 100%;
}

/*=====================================================*/
/* 이미지 100% */
.comments-img-size {
  width: 55px;
  height: 55px;
}

/*=====================================================*/
/* 사용자 이미지 */
.img-user-box {
  overflow: hidden;
}
.img-user-box > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*=====================================================*/
/* 
/myWork/createApp/codeApp/codeAppCreate/ 
= Modal 아이소에서 제공하는 전체 이미지 보기
 */
.modal-sample-img {
  width: calc(100% / 3);
  position: relative;
  padding-bottom: 34.5%;
  overflow: hidden;
}
.modal-sample-img > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1px;
}
.modal-sample-img-right {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
}
.modal-sample-img-right > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1px;
}
.check-img {
  position: relative;
  cursor: pointer;
}
.check-img:hover .check-icon {
  opacity: 1;
}
.check-img > .check-icon {
  position: absolute;
  top: 0;
  right: 10px;
  color: #eee;
  box-sizing: border-box;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;
  font-size: 30px;
}
