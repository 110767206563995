/** @format */
/*=====================================================*/
.table th {
  white-space: nowrap;
}
.cell-wrap {
  white-space: wrap !important;
}
.cell-nowrap {
  white-space: nowrap !important;
  overflow: hidden !important;
}
.table th {
  padding: 1rem 0.9375rem !important;
  background-color: #fff !important;
}
table th td {
  color: #333 !important;
  font-size: 14px !important;
}
.table td {
  padding: 1.4rem 0.9375rem !important;
}
.react-bootstrap-table table.table thead th {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #333 !important;
}
tbody > tr > td {
  background-color: rgba(191, 207, 231, 0.1) !important;
}
tbody tr:hover td,
.table-hover tbody tr:hover td {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-bootstrap-table-pagination-total {
  color: #333 !important;
}
/* 숨어있는 메뉴 - 맨 오른쪽 */
/*=====================================================*/
/*=== border table ===*/
.border-table thead th {
  border: 1px solid rgba(255, 255, 255, 1) !important;
}
.border-table td {
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
  color: #000 !important;
  font-size: 14px !important;
}
/*=== Expand ===*/
.react-bootstrap-table .row-expansion-style {
  padding: 30px 30px 15px 30px !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
}
.react-bootstrap-table .reset-expansion-style {
  background-color: #fff;
  padding: 0 !important;
}

.react-bootstrap-table-pagination-total {
  color: #888;
  font-size: 13px;
}
.expand-cell > b {
  padding-bottom: 7px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.expand-cell > b.text-danger {
  padding-top: 5px !important;
}

/*=====================================================*/
/*=== DataGrid Style ===*/
.table-search-bg {
  background-color: #f2f4f7;
}
.MuiDataGrid-root {
  border: none !important;
}
.MuiDataGrid-columnsContainer {
  border-bottom: none !important;
}
.MuiDataGrid-root .MuiDataGrid-colCellTitle {
  color: #999;
  font-weight: bold !important;
  font-size: 13px !important;
}
.MuiDataGrid-root .MuiDataGrid-row {
  background-color: 1px solid #f9fafb;
  margin: 5px 0;
}
.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
}
.MuiDataGrid-window {
  top: 55 !important;
}
.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  display: none !important;
}
.MuiDataGrid-root .MuiDataGrid-row:hover {
  transition: all 0.2s;
  cursor: pointer;
}
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #f6f9fc !important;
}
/*=====================================================*/
/*=== Pagination ===*/
.pagination {
  margin-bottom: 0 !important;
}
.react-bootstrap-table-pagination > div > .btn-group {
  margin-bottom: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.react-bootstrap-table-pagination > div > .btn-group > button {
  margin-right: 8px !important;
  border-radius: 3px !important;
  padding: 1px 10px !important;
}
/* 1 */
.table-left-btn-click,
.white-table-left-btn-click {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #5395ff !important;
  transition: ease-in-out 0.2s;
}
.table-left-btn,
.white-table-left-btn {
  background-color: #fff !important;
  color: #aaa !important;
}
/* Pagination-right */
.react-bootstrap-table-pagination {
  display: block !important;
}
.react-bootstrap-table-pagination > div:first-child {
  padding-left: 1rem;
}
.react-bootstrap-table-pagination > div:nth-child(2) {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
/* Pagination-right */
.pagination .page-item,
.react-bootstrap-table-pagination-list > ul > li {
  padding: 0 3px;
}
.react-bootstrap-table-pagination-list > ul > li > a.page-link,
.pagination .page-item .page-link {
  border-radius: 9999px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: bold;
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 2px solid rgba(255, 255, 255, 0.7) !important;
}
.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
.pagination .page-item .page-link > span {
  font-size: 13px !important;
  font-weight: black !important;
}
.pagination .page-item.active .page-link,
.pagination .page-item:hover .page-link,
.pagination .page-item:focus .page-link,
.pagination .page-item:active .page-link {
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: #5395ff !important;
  font-weight: bold !important;
}

/*=====================================================*/
/*=== 내용 카드 내부 리스트 ===*/
/* 드롭 다운 */
.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle {
  background-color: #fff !important;
  border-color: #e6e6e6 !important;
  color: #333 !important;
  padding: 0.7rem 1.2rem !important;
}
.react-bs-table-sizePerPage-dropdown > .dropdown-menu {
  transform: translate3d(0px, 20px, 0px);
}

.react-bs-table-no-data {
  font-size: 18px !important;
  font-weight: bold;
  padding: 10px 0;
  color: #5395ff;
}
.react-bs-table-no-data::after {
  /* content: "다시 검색해 주세요"; */
  content: '데이터가 없습니다.';
  display: block;
  padding: 10px 0;
  font-size: 13px !important;
  color: #888;
}
/*=====================================================*/

@media (max-width: 2200px) {
  .table-width {
    table-layout: auto;
  }
}
@media (max-width: 1705px) {
  .table-width {
    table-layout: fixed;
  }
}
@media (max-width: 1200px) {
  .table-width {
    table-layout: fixed;
  }
}
@media (max-width: 992px) {
  .table-width {
    table-layout: auto;
  }
}
@media (max-width: 767px) {
  .table-width {
    table-layout: fixed;
  }
}
@media (max-width: 480px) {
  .report-table > tbody > tr {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .report-table > tbody > tr > td {
    padding: 0.5rem 0;
  }
}
