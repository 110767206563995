/*=====================================================*/
/* 방문자 페이지 모달 */
.popup-wrap {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  height: auto;
  overflow-y: auto;
}

.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  /* max-width: 800px; */
  width: 100%;
  height: 100%;
  margin: 20px;
  position: relative;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 0.5rem !important;
}
.popup-header {
  flex: 0 0 auto;
  width: 100%;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* 닫기 버튼 아이콘 크기 */
.popup-header .MuiSvgIcon-root {
  font-size: 2rem;
  color: #888;
}
.popup-header .MuiIconButton-root:hover .MuiSvgIcon-root {
  transition: ease-in-out 0.2s;
  color: #333;
}

.popup-body {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  padding: 1rem 1.5rem 1rem 1.5rem;
  /* overflow-y: auto; */
  height: 100%;
}

.popup-footer {
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  padding: 1rem 3rem 2rem 3rem;
  display: flex;
  justify-content: space-between;
}

/*=====================================================*/
/* 아이소 모달 헤더 */
.aiso-modal {
  max-height: calc(100% - 1rem) !important;
}
.aiso-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  outline: 0;
}
.aiso-modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-left: 1rem;
}
.aiso-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 1.5rem;
}
.aiso-modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
}
/*=====================================================*/
.modal-content {
  border-radius: 0 !important;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modal-footer {
  justify-content: center !important;
}
.modal-footer > * {
  margin: 0.25rem 0.5rem !important;
}
/*=====================================================*/
/* request 정산 모달 */
.paid-modal > .modal-dialog > .modal-content {
  border: 1px solid #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
/*=====================================================*/
/* 모달 버튼 - O */
.btn-modal-o {
  color: #fff !important;
  background-color: #5395ff !important;
  /* border-radius: 9999px !important; */
}
.btn-modal-o:hover {
  background-color: #3a7fee !important;
  transition: all 0.3s;
}
.btn-modal-o:focus,
.btn-modal-o:active,
.btn-modal-o.active {
  transition: all 0.3s;
  box-shadow: 0 0 3px 3px #858d97;
}
/* 모달 버튼 - X */
.btn-modal-x {
  color: #fff !important;
  background-color: #f96868 !important;
  /* border-radius: 9999px !important; */
}
.btn-modal-x:hover {
  background-color: #f96868 !important;
  transition: all 0.3s;
}
.btn-modal-x:focus,
.btn-modal-x:active,
.btn-modal-x.active {
  transition: all 0.3s;
  box-shadow: 0 0 3px 3px #858d97;
}
/*=====================================================*/
