@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:100,400");
:root {
  --x: 0px;
  --y: 0px;
}

.hero {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero h1 {
  font-size: 100px;
  color: rgba(34, 34, 34, 0.9);
  animation: flex 3s ease-in-out alternate infinite;
}

@keyframes flex {
  0% {
    text-shadow: calc(var(--x) * -1) calc(var(--y) * -1) 0 rgba(101, 237, 255, 0.7),
      calc(var(--x) * -1.5) calc(var(--y) * 1.5) 0 rgba(118, 211, 247, 0.7),
      calc(var(--y) * -2) calc(var(--x) * -2) 0 rgba(0, 255, 213, 0.7), var(--x) var(--y) 0 rgba(95, 171, 221, 0.7);
  }
  100% {
    text-shadow: var(--x) var(--y) 0 rgba(145, 242, 249, 0.7), var(--x) calc(var(--y) * -1) 0 rgba(118, 247, 230, 0.7),
      var(--y) var(--x) 0 rgba(0, 255, 242, 0.7), calc(var(--x) * -2) calc(var(--y) * -2) 0 rgba(159, 228, 255, 0.7);
  }
}
