/** @format */

/*=====================================================*/
.shortcut-card-button {
  position: relative;
}
.shortcut-card-button .shortcut-card-button-hover {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(127, 129, 141, 0.493);
  border-radius: 3px;
}

.shortcut-card-button:hover .shortcut-card-button-hover {
  transition: all 0.45s ease;
  opacity: 1;
  cursor: pointer;
}
/*=====================================================*/
/*========== 카드 오버 ==========*/
/* 내 앱 정보 / 기본정보 / */
.shortcut-card-button {
  position: relative;
}
.shortcut-card-button .shortcut-card-button-hover {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(220, 226, 255, 0.493);
  border-radius: 3px;
}
.shortcut-card-button:hover .shortcut-card-button-hover {
  transition: all 0.45s ease;
  opacity: 1;
  cursor: pointer;
}

/*=====================================================*/
/*========== Profit CARD HOVER ==========*/
.shortcut-card-button-profit {
  position: relative;
}
.shortcut-card-button-profit > .shortcut-card-button-hover {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(220, 226, 255, 0.493);
  border-radius: 3px;
}
.shortcut-card-button-profit > .shortcut-card-button-hover > button {
  margin-top: 100px;
}
.shortcut-card-button-profit:hover .shortcut-card-button-hover {
  transition: all 0.45s ease;
  opacity: 1;
  cursor: pointer;
}
/*=====================================================*/
/*========== Profit 수익리스트 App Hover ==========*/
