/* === Plugin styles === */
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

@import '~bootstrap/scss/bootstrap';
@import './fonts';

/* === Icon fonts === */
@import '~ti-icons/css/themify-icons.css';
@import '~@mdi/font/scss/materialdesignicons';
@import 'font-awesome/css/font-awesome.min.css';
@import '~simple-line-icons/scss/simple-line-icons';
@import '~typicons.font/src/font/typicons.css';

/* ===  + Adobe Design === */
@import './design/visitor-header.scss';

/* === only AISO === */
@import './aisoCSS/base/aisoCard.css';
@import './aisoCSS/base/aisoTable.css';
@import './aisoCSS/base/aisoSearch.css';
@import './aisoCSS/base/aisoButton.css';
@import './aisoCSS/base/aisoModal.css';
@import './aisoCSS/base/aisoColor.css';
@import './aisoCSS/base/rightBottomBtn.css';
@import './aisoCSS/base/aisoInput.css';
@import './aisoCSS/base/aisoImage.css';
@import './aisoCSS/base/aisoHoverBox.css';
@import './aisoCSS/base/aisoBox.css';

/*** Home ***/
@import './aisoCSS/home/homeBg.css';

/*** market ***/
@import './aisoCSS/sign.css';

/*** Modal ***/
@import './aisoCSS/modal.scss';

@font-face {
  font-family: 'SpoqaHanSansNeo-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SpoqaHanSansNeo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  font-family: 'SpoqaHanSansNeo-Regular';
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-content {
  max-width: 1700px;
  margin: 0 auto;
  padding-left: 5rem;
  padding-right: 5rem;

  @media screen and (max-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.page-body-wrapper {
  height: 100%;
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;
    .main-panel {
      width: 100%;
      transition: none;
      .content-wrapper {
        padding: 0;
        > div {
          height: 100%;
        }
      }
      .content-wrapper-mywork {
        padding: 0;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.main-panel {
  // min-height: 100vh;
  width: calc(100% - 237px);
  position: absolute;
  right: 0;
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

body {
  height: 100vh !important;
  background-color: #f1f6fa;
}

a:hover {
  text-decoration: none;
}

.main-panel-user {
  background-color: #f1f6fa;
  width: 100%;
  position: absolute;
  right: 0;

  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  padding: 1.68rem 2.5rem;
  width: 100%;
  min-height: 100vh;
}

.content-wrapper-user {
  position: relative;
  min-height: calc(100vh - 70px);
  height: 100%;
}

.container-scroller {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.aiso_detail_wrap {
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  border: 5px solid rgba(255, 255, 255, 0.4) !important;
  border-radius: 35px;
}

.visitor-footer-bg {
  width: 100%;
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-top: 4px solid rgba(255, 255, 255, 0.6) !important;
}

.visitor-footer {
  max-width: 1700px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.aiso_glass_in_bg {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.rounded-visitor-market {
  border-radius: 80px !important;
}
