/** @format */

/*=====================================================*/
/* border */
.border-eee {
  border-color: #eee !important;
}
.border-ddd {
  border-color: #ddd !important;
}
.loader-demo-box,
.input-border {
  border: 1px solid #aab2bd !important;
}
.box-border-eee {
  border: 1px solid #eee !important;
  border-radius: 3px !important;
}

/*=====================================================*/
/*========== BackGround ==========*/
.bg-gray-aiso {
  background-color: #f9fafc !important;
}
.bg-gray-myaiso {
  background-color: #f2f4f7 !important;
}
.bg-gray-input {
  background-color: #f6f9fc !important;
}
.bg-gray-base {
  background-color: #f9fafc;
}
.bg-gray-xl {
  background-color: #ebedf0;
}
.bg-gray {
  background-color: #aaa;
}
.bg-lightGray {
  background-color: #eee;
}
.bg-fff {
  background-color: #fff !important;
}
.bg-danger {
  background-color: #f96868 !important;
}
.bg-lightBlue {
  background-color: #91c5ff;
}
/*=====================================================*/
/*========== Text ==========*/
.text-danger {
  color: #f96868 !important;
}
.text-333 {
  color: #333 !important;
}
.text-555 {
  color: #555 !important;
}
.text-888 {
  color: #888 !important;
}
.text-aaa {
  color: #aaa !important;
}
/*=====================================================*/
